(function ($) {
    function msieversion() {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");
        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer, return version number
        {
            return true;// alert(parseInt(ua.substring(msie + 5, ua.indexOf(".", msie))));
        }
        return false;
    }
    $(function () {

        if ($(window).width() > 576) {
            $('.slider-ourproduct .item-ourproduct .info-product').matchHeight();
        }
        

        if ($(".slider-banner-home").length > 0 && msieversion()) {
            $(".slider-banner-home").addClass("detect-ie");
        }
        var owlSliderHome = $(".slider-banner-home").owlCarousel({
            items: 1,
            dots: 0,
            nav: 0,
            loop: false,
            margin: -175,
            smartSpeed: 800
        });
        var owlSliderMini = $(".slider-mini-home").owlCarousel({
            animateOut: 'fadeOut',
            animateIn: 'fadeIn',
            items: 1,
            smartSpeed: 200,
            mouseDrag: false,
            touchDrag: false
        });
        owlSliderHome.on('changed.owl.carousel', function (event) {
            var index = owlSliderHome.find(".owl-item.active .item-banner-home").data("index");
            if (!index) { return; }
            owlSliderMini.trigger("to.owl.carousel", index);
        }).on('translated.owl.carousel', function (event) {
            if ($('.slider-banner-home .owl-item.active').is(':first-child')) {
                $('.control-slider').children().removeClass('disabled');
                $('.control-slider .btn-prev').addClass('disabled');
            } else if ($('.slider-banner-home .owl-item.active').is(':last-child')) {
                $('.control-slider').children().removeClass('disabled');
                $('.control-slider .btn-next').addClass('disabled');
            } else {
                $('.control-slider').children().removeClass('disabled');
            }
            
        });
        
        $(".item-banner-home").on("click", function (e) {
            e.preventDefault();
            var index = $(this).data("index");
            if (!index) { return; }
            owlSliderHome.trigger("to.owl.carousel", index - 1);
        });
        $(".the-banner-home .btn-prev, .the-banner-home .btn-next").on("click", function (e) {
            e.preventDefault();
            if (!$(this).hasClass('disabled')) {
                $(".slider-banner-home").trigger(($(this).hasClass("btn-prev") ? "prev" : "next") + ".owl.carousel");
            }
            // $(".slider-banner-home").trigger(($(this).hasClass("btn-prev") ? "prev" : "next") + ".owl.carousel");
        });
        var clearSliderBannerHome;
        var runSliderBannerHome = function () {
            var mg = -parseInt($(".slider-banner-home .owl-item").height() * 0.178);
            $(".slider-banner-home").trigger("destroy.owl.carousel");
            $(".slider-banner-home").owlCarousel({
                items: 1,
                dots: 0,
                nav: 0,
                loop: false,
                margin: $(window).width() >= 992 ? mg : mg * 2
            });
        };
        // if (msieversion()) {
        runSliderBannerHome();
        $(window).on("resize", function () {
            clearTimeout(clearSliderBannerHome);
            clearSliderBannerHome = setTimeout(runSliderBannerHome, 250);
        });
        // }

        /* OUR PRODUCT */
        
        $(".slider-ourproduct .owl-carousel").owlCarousel({
            items: 10,
            nav: true,
            navText: ["", ""],
            dots: true,
            smartSpeed: 800,
            mouseDrag: false,
            responsive: {
                0: { items: 1, margin: 10 },
                992: { items: 2 }
            }
        });
        $(".the-home-ourproduct .btn-prev, .the-home-ourproduct .btn-next").on("click", function (e) {
            e.preventDefault();
            $(".slider-ourproduct .owl-carousel").trigger(($(this).hasClass("btn-prev") ? "prev" : "next") + ".owl.carousel");
        });

        /* LAST NEWS */
        $(".the-home-lastnews .item-last-news .title").matchHeight({ byRow: false });
        $(".the-home-lastnews .item-last-news .intro").matchHeight({ byRow: false });

        /* THE-FEATURE-LIST */
        $("section.the-feature-list .owl-carousel").data("total-item", $(this).find(".home-feature-items").length);
        $("section.the-feature-list .home-feature-items").each(function () { $(this).attr("data-index", $(this).index() + 1); });
        $("section.the-feature-list .owl-carousel").owlCarousel({
            items: 3,
            loop: true,
            nav: true,
            navText: ["", ""],
            dots: true,
            smartSpeed: 800,
            margin: 10,
            mouseDrag: false,
            responsive: {
                0: { items: 1 },
                575: { items: 2 },
                992: { items: 3 }
            }
        });
        $(".slider-product-top-row .owl-next").on('click', function () {
            $(".slider-product-second-row .owl-next").trigger("click");
            calProductSlider();
            calProductSliderSecond();
            removeNextOwlActive();
        });
        $(".slider-product-second-row .owl-prev").on('click', function () {
            $(".slider-product-top-row .owl-prev").trigger("click");
            calProductSlider();
            calProductSliderSecond();
            removeNextOwlActive();
        });
        // calculator with
        calProductSlider();
        calProductSliderSecond();
        $(window).resize(function () {
            calProductSlider();
            calProductSliderSecond();
            removeNextOwlActive();
        });

        function removeNextOwlActive() {
            if ($(window).width() > 992) {
                var $length = $(".slider-product-top-row").find(".owl-stage");
                var $info_product = $length.find('.owl-item.active').first();
                $product_parent = $info_product.find('.item-slider-ourproduct');
                $product_parent.css('transform', 'translate3d(0,0,0)');
                $product_parent.removeClass('active-hover');

                var $length_second = $(".slider-product-second-row").find(".owl-stage");
                var $info_product_second = $length_second.find('.owl-item.active').last();
                $product_parent_second = $info_product_second.find('.item-slider-ourproduct');
                $product_parent_second.css('transform', 'translate3d(0,0,0)');
                $product_parent_second.removeClass('active-hover');

            }
        }
        function calProductSlider() {
            if ($(window).width() > 992) {
                // var $length = $(".slider-product-top-row").find(".owl-stage");
                // var $info_product = $length.find('.owl-item.active').last();
                // var $info_product_first = $length.find('.owl-item.active').first();
                // $info_product_first.removeClass('active-hover');
                // var $cal = $info_product.find('.info-product');
                // var $cal_width = $cal.width() + 100;
                // $get_css = 'translate3d( ' + $cal_width + 'px' + ', 0 , 0 )';
                // $info_parent = $cal.closest('.item-slider-ourproduct');
                // $info_parent.css('transform', $get_css);
                // $parent_hover = $info_parent.addClass('active-hover');
            }
        }

        function calProductSliderSecond() {
            if ($(window).width() > 992) {
                var $length = $(".slider-product-second-row").find(".owl-stage");
                var $info_product = $length.find('.owl-item.active').first();
                var $info_product_first = $length.find('.owl-item.active').last();
                $info_product_first.removeClass('active-hover');
                var $cal = $info_product.find('.info-product');
                var $cal_width = $cal.width() + 120;
                $get_css = 'translate3d( -' + $cal_width + 'px' + ', 0 , 0 )';
                $info_parent = $cal.closest('.item-slider-ourproduct');
                $info_parent.css('transform', $get_css);
                $parent_hover = $info_parent.addClass('active-hover');
            }
        }
        // Help click 2 section prev and next can be move :)
        $("section.the-feature-list .owl-carousel .owl-item").on("click", function (e) {
            if ($(this).hasClass("owl-center-prev") || $(this).hasClass("owl-center-next")) {
                $("section.the-feature-list .owl-carousel").trigger(($(this).hasClass("owl-center-prev") ? "prev" : "next") + ".owl.carousel");
            }
        });

        $("section.the-feature-list .owl-carousel").trigger('prev.owl.carousel');
        function setClassCenter($this, e) {
            setTimeout(function () {
                $this.find(".owl-item").removeClass("owl-center-item owl-center-prev owl-center-next");
                if ($(window).width() < 992) { return; }
                var count_dot = e ? e.page.size : $this.find(".owl-item.active").length;
                var $center_Item = $this.find(".owl-item.active").eq(Math.round(count_dot / 2) - 1);
                $center_Item.addClass("owl-center-item");
                $center_Item.prev().addClass("owl-center-prev");
                $center_Item.next().addClass("owl-center-next");

                if ($this.find(".owl-page").length < 1 && $(window).width() >= 992) { $this.append("<div class='owl-page'></div>"); }
                if ($(window).width() >= 992) { $this.find(".owl-page").html($this.find(".owl-item.owl-center-item .home-feature-items").attr("data-index") + "/<span>" + $this.data("total-item") + "</span>"); }
            }, 150);
        }
        setClassCenter($("section.the-feature-list .owl-carousel"));
        $("section.the-feature-list .owl-carousel").on("changed.owl.carousel", function (e) { setClassCenter($(this), e); });

    });
})(jQuery);