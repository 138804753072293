(function ($) {
    function stopOwlPropagation(element) {
        $(element).on('initialized.owl.carousel changed.owl.carousel to.owl.carousel next.owl.carousel prev.owl.carousel destroy.owl.carousel', function (e) { e.stopPropagation(); });
    }

    function sliderArchive() {
        $(".owl-promote-product").owlCarousel({
            items: 1,
            margin: 20,
            dots: false,
            animateOut: "fadeOut",
            nav: true,
            navText: ['<span></span>PREVIOUS', 'NEXT<span></span>'],
        });
        $(".featured-products-slide").on('initialized.owl.carousel changed.owl.carousel', function (event) {
            var items = event.item.count,     // Number of items
                item = event.item.index,     // Position of the current item
                current_pagi = $(this).closest('.featured-products').find('.current-page'),
                total_pagi = $(this).closest('.featured-products').find('.total-page');
            current_pagi.html(item + 1 + " ");
            total_pagi.html("/" + items);
            $('.featured-products-slide .product-slide-block').css('pointer-events', 'none');
            var owl_height;
            setTimeout(function () {
                $('.featured-products-slide .product-slide-block').css('pointer-events', 'auto');
                owl_height = $(".featured-products-slide").find('.owl-height').height();
            }, 500);
            $('.featured-products-slide .product-slide-block').hover(function () {
                var this_owl = $(this).closest('.owl-carousel').find('.owl-height');
                if (!this_owl) return;
                this_owl.stop().css('height', owl_height + 208);
            }, function () {
                var this_owl = $(this).closest('.owl-carousel').find('.owl-height');
                this_owl.stop().css('height', owl_height);
            });
        }).owlCarousel({
            items: 1,
            touchDrag: false,
            mouseDrag: false,
            margin: 20,
            dots: false,
            autoHeight: true
        }).on('change.owl.carousel', function () {
        }).on('changed.owl.carousel', function () {
            $('.featured-products-slide .product-slide-block').css('pointer-events', 'none');
            var owl_height;
            setTimeout(function () {
                $('.featured-products-slide .product-slide-block').css('pointer-events', 'auto');
                owl_height = $(".featured-products-slide").find('.owl-height').height();
            }, 500);
            $('.featured-products-slide .product-slide-block').hover(function () {
                var this_owl = $(this).closest('.owl-carousel').find('.owl-height');
                if (!this_owl) return;
                this_owl.stop().css('height', owl_height + 208);
            }, function () {
                var this_owl = $(this).closest('.owl-carousel').find('.owl-height');
                this_owl.stop().css('height', owl_height);
            });
        }).on('resized.owl.carousel', function () {

        });

        $(".new-products-slide").on('initialized.owl.carousel changed.owl.carousel', function (event) {
            var items = event.item.count,     // Number of items
                item = event.item.index,     // Position of the current item
                current_pagi = $('.new-products-nav').find('.current-page'),
                total_pagi = $('.new-products-nav').find('.total-page');
            current_pagi.html(item + 1 + " ");
            total_pagi.html("/" + items);
        }).owlCarousel({
            items: 1,
            touchDrag: false,
            mouseDrag: false,
            margin: 20,
            dots: false,
            autoHeight: true
        }).on('changed.owl.carousel', function () {
            $('.new-products-slide .product-slide-block').css('pointer-events', 'none');
            var owl_height;
            setTimeout(function () {
                $('.new-products-slide .product-slide-block').css('pointer-events', 'auto');
                owl_height = $(".new-products-slide").find('.owl-height').height();
            }, 500);
            $('.new-products-slide .product-slide-block').hover(function () {
                var this_owl = $(this).closest('.owl-carousel').find('.owl-height');
                if (!this_owl) return;
                this_owl.stop().css('height', owl_height + 208);
            }, function () {
                var this_owl = $(this).closest('.owl-carousel').find('.owl-height');
                this_owl.stop().css('height', owl_height);
            });
        });
        $(".new-products-nav .products-nav-l").on("click", function () {
            $(".new-products-slide").trigger('prev.owl.carousel');
        });

        $(".new-products-nav .products-nav-r").on("click", function () {
            $(".new-products-slide").trigger('next.owl.carousel');
        });

    }

    function toggleCategoryMobile() {
        $(".coll-item-product .collapse").removeClass('show');
        $(".coll-item-product").children('a[data-toggle=collapse]').addClass('collapsed');
    }


    $(function () {

        $(document).ready(function () {
            $('#wrtw-sidebar').stick_in_parent({
                offset_top: $("header.header-page").outerHeight()
                    + (($("body").hasClass("admin-bar") && $(window).width() >= 768) ? 32 : 0)
            });
        });
        $(window).on("resize", function () {
            if ($(window).width() < 992) { $('#wrtw-sidebar').trigger("sticky_kit:detach"); }
        });

        stopOwlPropagation('.owl-carousel');
        sliderArchive();
        if ($(window).width() < 768) {
            toggleCategoryMobile();
        }

        $(document).on('facetwp-loaded', function () {
            $('.new-products-slide').owlCarousel('destroy');
            $('.owl-inner-slide-product').owlCarousel('destroy');
            $('.new-products-slide').owlCarousel('destroy');
            $('.owl-inner-slide-product').owlCarousel('destroy');

            $(".new-products-slide").on('changed.owl.carousel', function (event) {
                var items = event.item.count,     // Number of items
                    item = event.item.index,     // Position of the current item
                    current_pagi = $('.new-products-nav').find('.current-page'),
                    total_pagi = $('.new-products-nav').find('.total-page');
                current_pagi.html(item + 1 + " ");
                total_pagi.html("/" + items);
            }).owlCarousel({
                items: 1,
                touchDrag: false,
                mouseDrag: false,
                margin: 20,
                dots: false,
                autoHeight: true

            }).on('change.owl.carousel', function () {
            }).on('changed.owl.carousel', function () {
                $('.new-products-slide .product-slide-block').css('pointer-events', 'none');
                var owl_height;
                setTimeout(function () {
                    $('.new-products-slide .product-slide-block').css('pointer-events', 'auto');
                    owl_height = $(".new-products-slide").find('.owl-height').height();
                }, 500);
                $('.new-products-slide .product-slide-block').hover(function () {
                    var this_owl = $(this).closest('.owl-carousel').find('.owl-height');
                    if (!this_owl) return;
                    this_owl.stop().css('height', owl_height + 208);
                }, function () {
                    var this_owl = $(this).closest('.owl-carousel').find('.owl-height');
                    this_owl.stop().css('height', owl_height);
                });
            }).on('resized.owl.carousel', function () {

            });

            $('.owl-inner-slide-product').owlCarousel({
                items: 2,
                margin: 20,
                stagePadding: 60,
                nav: $(".owl-inner-slide-product .color-product").length > 2,
                navText: "",
                dots: false,
                mouseDrag: false,
                touchDrag: false
            });

            $('.new-products-nav').find('.current-page').html(1 + " ");
            $('.new-products-nav').find('.total-page').html("/" + $('.new-products-slide .owl-product-block').length);

        });

    });
})(jQuery);
