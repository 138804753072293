(function ($) {
    function weekScrollBar() {
        $('.week-list ul').scrollbar();
    }

    var headerWorkout = function () {
        var header = $('.header-workout');
        if ($(window).scrollTop() >= $('.header-page').outerHeight() - 20) {
            header.addClass('fixed');
            header.next().css('padding-top', header.outerHeight());
        } else {
            header.removeClass('fixed');
            header.next().css('padding-top', '');
        }
    };

    function weekListSelect() {
        $('.week-list a').on('click', function (e) {
            e.preventDefault();
            var eq = $(this).parent().index();
            $('.week-list a').removeClass('active');
            $(this).addClass('active');
            $('.owl-card, .list-meal').trigger('to.owl.carousel', eq);
        });
    }

    function cardWorkoutSlide() {
        if ($('.owl-card').length < 1) { return; }
        var owl = $('.owl-card');
        owl.on('initialized.owl.carousel', function () {

        }).owlCarousel({
            items: 1,
            dots: false,
            stagePadding: 90,
            autoHeight: true,
            responsive: {
                0: {
                    stagePadding: 0,
                    margin: 30,
                },
                768: {
                    stagePadding: 0,
                    margin: 30,
                },
                992: {
                    stagePadding: 100,
                    margin: 80,

                },
                1200: {
                    stagePadding: 180,
                    margin: 50,

                }

            }

        }).on('translated.owl.carousel', function (e) {
            var item = e.item.index;
            owl.not($(this)).trigger('to.owl.carousel', item);
            $('.week-list a').removeClass('active');
            $('.week-list li').eq(item).find('a').addClass('active');
        }).on('changed.owl.carousel', function (event) {
            /*var height = $(this).find('.owl-height').height();
            $(this).closest('.wrap-card-row').find('.content-card').css('height', height);*/
        });

    }

    function mealMenuSlide() {
        if ($(".list-meal").length < 1) { return; }

        var owl = $('.list-meal');
        owl.owlCarousel({
            items: 1,
            stagePadding: 0,
            dots: false,
            autoHeight: true,
            touchDrag: false
        }).on('translated.owl.carousel', function (e) {
            var item = e.item.index;
            owl.not($(this)).trigger('to.owl.carousel', item);
            $('.week-list a').removeClass('active');
            $('.week-list li').eq(item).find('a').addClass('active');
        });
    }

    function ui_meal_modal_custom() {
        if ($(".meal-plan-modal").length < 1) { return; }
        $(".meal-plan-modal").addClass("is-hide").fadeOut(200);

        // Function open modal.
        $(document).on("click", ".scroll-grid-content .wrap-meal", function (e) {
            e.preventDefault();
            var $target = $(this).data("target");
            $(document).find("" + $target + "").addClass("is-fixed");
            if ($(".meal-plan-modal").hasClass("is-fixed") || $(".meal-plan-modal").hasClass("is-hide")) {
                $(window).width() > 992 ? $("body").addClass("modal-open") : $("body");
                $(".meal-plan-modal").removeClass("is-hide").fadeIn(200);
                $(window).width() < 992 ? $(".meal-plan-modal").addClass("is-mobile") : $(".meal-plan-modal").removeClass("is-mobile");
            }
        });
        // Function close modal.
        $(document).on("click", ".meal-plan-modal .btn-dismiss", function (e) {
            e.preventDefault();
            var modal = $(this).parents(".meal-plan-modal");
            if (modal.hasClass("is-fixed")) {
                modal.removeClass("is-fixed");
                $(window).width() > 992 ? $("body").removeClass("modal-open") : $("body");
                $(window).width() < 992 ? $(".meal-plan-modal").addClass("is-mobile") : $(".meal-plan-modal").removeClass("is-mobile");
                $(".meal-plan-modal").fadeOut(300);
            }
        });
    }
    function ui_resize_modal() {
        // Function resize window modal.
        if ($(".meal-plan-modal").hasClass("is-fixed")) {
            $(window).width() > 992 ? $("body").addClass("modal-open") : $("body").removeClass("modal-open");
            $(".meal-plan-modal").addClass("is-mobile");
            $(window).width() < 992 ? $(".meal-plan-modal").addClass("is-mobile") : $(".meal-plan-modal").removeClass("is-mobile");
        }
    }
    $(function () {
        weekScrollBar();
        cardWorkoutSlide();
        weekListSelect();
        mealMenuSlide();
        // headerWorkout();
        // $(window).on('scroll', headerWorkout);
        $(".meal-plan-modal .scrollbar-macosx").scrollbar();
        ui_meal_modal_custom();
        ui_resize_modal();
        $(window).on("resize", ui_resize_modal);
    });
})(jQuery);