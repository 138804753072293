(function ($) {

    function owlProductSingle() {
        if ($(".owl-product-single").length > 0) {
            $(".owl-product-single").on('initialized.owl.carousel', function (e) {
                var item_count = e.item.count;
                if (item_count > 5) {
                    $('.product-single-img').append('<div class="nav-prev"></div><div class="nav-next"></div>');
                }
            }).owlCarousel({
                items: 1,
                nav: false,
                margin: 20,
                mouseDrag: false,
                touchDrag: false,
                animateOut: "fadeOut",
            });
        }
        var dotcount = 1;

        $('.owl-product-single .owl-dot').each(function (i) {
            $(this).addClass('dotnumber' + dotcount);
            $(this).attr('data-info', dotcount);
            dotcount = dotcount + 1;
            if (i < 5) {
                $(this).addClass('show');
            }
        });

        var slidecount = 1;

        $('.owl-product-single .owl-item').not('.cloned').each(function () {
            $(this).addClass('slidenumber' + slidecount);
            slidecount = slidecount + 1;
        });

        $('.owl-product-single .owl-dot').each(function () {
            var info = $(this).data('info');
            var slidesrc = $('.slidenumber' + info + ' img').attr('src');
            $(this).css("background-image", "url(" + slidesrc + ")");
        });

        $(".owl-colors").owlCarousel({
            items: 4,
            margin: 20,
            nav: $(".owl-colors .color-product").length > 4,
            navText: "",
            dots: false
        });

        $('.nav-next').on('click', function () {
            var owl_dot = $('.owl-product-single .owl-dot');
            var owl_dot_show = $('.owl-product-single .owl-dot.show');
            $('.nav-prev').show();
            if (owl_dot_show.last().index() == (owl_dot.length - 2)) {
                $(this).hide();
            }
            if (owl_dot_show.last().is(':last-child')) return;
            owl_dot_show.last().next().addClass('show');
            owl_dot_show.first().removeClass('show');
        });

        $('.nav-prev').on('click', function () {
            var owl_dot_show = $('.owl-product-single .owl-dot.show');
            $('.nav-next').show();
            if (owl_dot_show.first().index() == 1) {
                $(this).hide();
            }
            if (owl_dot_show.first().is(':first-child')) return;
            owl_dot_show.first().prev().addClass('show');
            owl_dot_show.last().removeClass('show');
        });

    }


    function eventSingleProduct() {
        $(".owl-product-single .owl-dot").hover(function () {
            $(this).trigger('click');
        })

        $(".owl-colors .color-product").on('click', function () {
            var thisColor = $(this).data("color");
            thisColorID = $(this).data("colorid"),
                thisProduct = $(this).closest('.product-single-info'),
                addToCart = thisProduct.find('.add-to-cart-single');
            if ($('.color-name').length > 0) {
                $('.color-name').text(thisColor);
            }
            addToCart.attr({
                'data-color': thisColor,
                'data-colorid': thisColorID
            });
            $(".owl-colors .color-product").removeClass("selected-color");
            $(this).addClass("selected-color");
        });

        $('.product-single-info select.size-option').on('change', function () {
            var thisSize = $(this).val(),
                thisProduct = $(this).closest('.product-single-info');
            addToCart = thisProduct.find('.add-to-cart-single');
            addToCart.attr('data-size', thisSize);
        });



        $(".out-of-stock").on("click", function () {
            alert("Color " + $(this).data("colorid") + " is out of stock");
        });
        $(".add-to-cart-single").on('click', function () {
            console.log("add-to-cart-single");
        });

        $(".qty-box input").on('change', function () {
            var inputVal = parseInt($(this).val());
            inputVal = (inputVal > 1) ? inputVal : 1;
            inputVal = (inputVal < 10) ? inputVal : 10;
            $(this).val(inputVal);
            $('.add-to-cart-single').attr('data-qty', inputVal);
        });

        $(".minus-btn").on("click", function () {
            var inputVal = parseInt($(".qty-box input").val());
            inputVal = (inputVal > 1) ? (inputVal - 1) : 1;
            $(".qty-box input").val(inputVal);
            $('.add-to-cart-single').attr('data-qty', inputVal);
        });

        $(".add-btn").on("click", function () {
            var inputVal = parseInt($(".qty-box input").val());
            inputVal = (inputVal < 10) ? (inputVal + 1) : 10;
            $(".qty-box input").val(inputVal);
            $('.add-to-cart-single').attr('data-qty', inputVal);
        });

        $(".add-to-list").on('click', function () {
            var thisID = $(".product-single-info").data("productid");
            console.log(thisID);
        });
    }




    function eventProductSlide() {
        $(document).on('click', '.color-product', function () {
            var thisColor = $(this).data("color"),
                thisColorID = $(this).data("colorid"),
                thisProduct = $(this).closest('.product-slide-block'),
                addToCart = thisProduct.find('.add-to-cart');
            addToCart.attr({
                'data-color': thisColor,
                'data-colorid': thisColorID
            });
            $(this).closest('.owl-inner-slide-product').find(".color-product").removeClass("selected-color");
            $(this).addClass("selected-color");
        });

        $(document).on('change', '.product-slide-block select.size-option', function () {
            var thisSize = $(this).val(),
                thisProduct = $(this).closest('.product-slide-block');
            addToCart = thisProduct.find('.add-to-cart');
            addToCart.attr('data-size', thisSize);
        });
    }

    function productSlide() {
        $(".product-slide").owlCarousel({
            items: 4,
            margin: 20,
            nav: true,
            navText: ['', ''],
            dots: false,
            responsive: {
                0: {
                    items: 1
                },
                641: {
                    items: 2
                },
                992: {
                    items: 3
                },
                1440: {
                    items: 4
                }
            }

        });

        $(".owl-inner-slide-product").owlCarousel({
            items: 2,
            margin: 20,
            stagePadding: 60,
            nav: $(".owl-inner-slide-product .color-product").length > 2,
            navText: "",
            dots: false,
            mouseDrag: false,
            touchDrag: false
        });
    }

    function socialShare() {
        $("#share").jsSocials({
            showLabel: false,
            showCount: false,
            shareIn: "popup",
            shares: ["email", "facebook", "pinterest"]
        });
    }

    function cartEvent() {
        $("#add-to-cart-single").on("click", function (e) {
            e.preventDefault();
            if (!$(this).hasClass("expand")) {
                $("#panel-cart").addClass("expand");
                $().backdrop_show({ callbackhide: function () { $("#panel-cart,#panel-main,.btn-menu-top").removeClass("expand"); }, class: "panel-main" });
            }
            else {
                $().backdrop_hide({ callbackhide: function () { $("#panel-cart").removeClass("expand"); } });
            }
        });

        // Close cart
        $("#panel-cart .close-btn").on("click", function (e) {
            e.preventDefault();
            $().backdrop_hide({ callbackhide: function () { $("#panel-cart").removeClass("expand"); } });
        });

        $(".to-menu-panel").on('click', function (e) {
            e.preventDefault();
            $("#panel-main, .btn-menu-top").addClass("expand");
            $("#panel-cart").removeClass("expand");
        });
    }

    function popupVideo() {
        $(document).ready(function () {
            $('.popup-youtube').magnificPopup({
                disableOn: 700,
                type: 'iframe',
                mainClass: 'mfp-fade',
                removalDelay: 160,
                preloader: false,
                fixedContentPos: false
            });
        });

    }

    $(function () {
        owlProductSingle();
        eventProductSlide();
        productSlide();
        eventSingleProduct();
        socialShare();
        $('.zoom-image').magnify();
        cartEvent();
        popupVideo();
    });
})(jQuery);
