(function ($) {
    $(function () {
        $(".btn-menu-top").on("click", function (e) {
            e.preventDefault();
            if (!$(this).hasClass("expand")) {
                $("#panel-main, .btn-menu-top").addClass("expand");
                $().backdrop_show({ callbackhide: function () { $("#panel-main, .btn-menu-top").removeClass("expand"); }, class: "panel-main" });
            }
            else {
                $().backdrop_hide({ callbackhide: function () { $("#panel-main, .btn-menu-top").removeClass("expand"); } });
            }
        });

        var $header = $("header.header-page");
        var hasFixed = function (direction) {
            var scrollTop = $(window).scrollTop();
            if (scrollTop > 10) {
                $header.addClass("fixed");
                // if (scrollTop < 500) {
                //     $header.addClass("no-animation");
                // } else {
                //     $header.removeClass("no-animation");
                // }
                // if (direction > 0) {
                //     if (scrollTop > ($(window).width() < 575 ? 50 : 120)) {
                //         $header.addClass("fixed fix-down").removeClass("fix-up");
                //     }
                // } else {
                //     $header.addClass("fixed fix-up").removeClass("fix-down");
                // }
                $header.removeClass('header-white-bg');
            } else {
                $header.removeClass("fixed no-animation fix-down fix-up no-animation");
                if(scrollTop == 0) {
                    $header.removeClass('header-white-bg');
                } else {
                    $header.addClass('header-white-bg');
                }
            }
        };
        $(window).on("scroll", hasFixed);
        $('.main-menu li').hover(function() {
            $(this).parents('.header-page').addClass('header-white-bg');
            }, 
            function() {
            $(this).parents('.header-page').removeClass('header-white-bg');
        });
        $( ".sub-menu" ).mouseover(function() {
            $( this ).parents('.header-page').addClass('header-white-bg');
        })
        .mouseout(function() {
            $( this ).parents('.header-page').removeClass('header-white-bg');
        });
        $( ".main-menu" ).mouseover(function() {
            $( this ).parents('.header-page').addClass('header-white-bg');
        })
        .mouseout(function() {
            $( this ).parents('.header-page').removeClass('header-white-bg');
        });
        // Scrollbar panel
        $('.panel-scroll .scrollbar-macosx').scrollbar();

        // Close panel
        $(".panel-right .close-btn").on("click", function (e) {
            e.preventDefault();
            $().backdrop_hide({ callbackhide: function () { $("#panel-main, .btn-menu-top").removeClass("expand"); } });
        });

        // More effect when expand collapse
        $(".coll-item a[data-toggle='collapse']").on("click", function (e) {
            $(this).parent().attr("data-show", $(this).hasClass("collapsed"));
        });
        $('#datepicker').datepicker({
            uiLibrary: 'bootstrap4',
            changeMonth: true,
            changeYear: true,
            yearRange: '-50:+0'
        });
        $('#datepicker').on("click", function () {
            $(".input-group-append .gj-icon").trigger("click");
        });
        $('#datepicker-icons').on('click', function (e) {
            e.preventDefault();
            $(this).prev().trigger('focus');
        });
    });
    
    

})(jQuery);