(function ($) {


    function removeCartItem() {
        $(".remove-item").on('click', function (e) {
            e.preventDefault();
            var currentBlock = $(this).closest('li'),
                itemName = currentBlock.find('.item-name').text();
            currentBlock.slideUp(400, function () {
                var numberItem = $('.cart-list .item-name:visible').length;
                $('.cart-review-heading span').text(numberItem);
            }).after("<li class='remove-item-block'><span>" + itemName + "</span> was removed from your cart.</li>");
            $('.remove-item-block').slideDown();
        });
    }

    function sliderCart() {
        $(".wistlist-slide").on('initialized.owl.carousel changed.owl.carousel', function (event) {
            var items = event.page.count,     // Number of items
                item = event.page.index,     // Position of the current item
                current_pagi = $(this).closest('.featured-products').find('.current-page'),
                total_pagi = $(this).closest('.featured-products').find('.total-page');
            current_pagi.html(item + 1 + " ");
            total_pagi.html("/" + items);
        }).owlCarousel({
            items: 3,
            touchDrag: false,
            mouseDrag: false,
            margin: 20,
            dots: false,
            slideBy: 'page',
            responsive: {
                0: {
                    items: 1
                },
                641: {
                    items: 2
                },
                768: {
                    items: 3
                }
            }
        });

        $(".also-bought-slide").on('initialized.owl.carousel changed.owl.carousel', function (event) {
            var items = event.page.count,     // Number of items
                item = event.page.index,     // Position of the current item
                current_pagi = $(this).closest('.featured-products').find('.current-page'),
                total_pagi = $(this).closest('.featured-products').find('.total-page');
            current_pagi.html(item + 1 + " ");
            total_pagi.html("/" + items);
        }).owlCarousel({
            items: 3,
            touchDrag: false,
            mouseDrag: false,
            margin: 20,
            slideBy: 'page',
            dots: false,
            responsive: {
                0: {
                    items: 1
                },
                641: {
                    items: 2
                },
                768: {
                    items: 3
                }

            }
        });

        $(".featured-products-nav .products-nav-l").on("click", function () {
            $(this).closest('.featured-products').find('.owl-carousel').trigger('prev.owl.carousel');
        });

        $(".featured-products-nav .products-nav-r").on("click", function () {
            $(this).closest('.featured-products').find('.owl-carousel').trigger('next.owl.carousel');
        });
    }

    $(function () {
        removeCartItem();
        sliderCart();
    });

})(jQuery);
