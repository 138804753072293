(function ($) {
    $(function () {
        $('.week-list-mb').on('click', function (e) {
            $(this).next('.week-list-filter-mb').toggleClass('active');
            var $parents3 = $(this).parents('.header-workout');
            var $get_day = $('#get-scroll-id').val();
            $parents3.next('.scroll-grid-content').addClass('active');
            if ( $get_day === '' ) {
                $('#get-scroll-id').attr('value', '#scroll-1');
                $('.meal-plan-choose-day').find('a[href="#scroll-1"]').addClass('active');
            }
        });
        $('.meal-plan-back').on('click', function (e) {
            $(this).parents('.week-list-filter-mb').removeClass('active');
            var $parents_back = $(this).parents('.header-workout');
            $parents_back.next().removeClass('active');
        });
        $('.meal-plan-choose-day a').on('click', function (e) {
            e.preventDefault();
            var eq = $(this).parent().index();
            $('.meal-plan-choose-day a').removeClass('active');
            $(this).addClass('active');
            $('.owl-card, .list-meal').trigger('to.owl.carousel', eq);
            var $parents1 = $(this).parents('.header-workout');
            $parents1.next('.scroll-grid-content').addClass('active');
            $id_current = $(this).attr('href');
            $('#get-scroll-id').val($id_current);
        });
        $('.meal-plan-day a').on('click', function (e) {
            e.preventDefault();
            var eq = $(this).parent().index();
            $('.meal-plan-day a').removeClass('active');
            $(this).addClass('active');
            $('.owl-card, .list-meal').trigger('to.owl.carousel', eq);
            var $parent_li = $(this).parents('li');
            var $index_li = $parent_li.index();
            $('#get-index-week').val($index_li);
            var $parents2 = $(this).parents('.header-workout');
            $parents2.next('.scroll-grid-content').addClass('active');
        });
        $('.btn-filter').on('click', function (e) {
            var $id_current_filter = $('#get-scroll-id').val();
            var $id_week = $('#get-index-week').val();
            var $mean_wrap = $('.meal-wrap');
            $mean_wrap.find('.wrap-row').removeClass('none');
            $mean_wrap.find('.wrap-row').removeClass('active');
            $mean_wrap.find('.wrap-row').addClass('none');
            $($id_current_filter).addClass('active');
            $('.meal-plan-back').trigger('click');
            var $parent_week = $('.week-list').find('li').eq($id_week);
            $parent_week.find('a').trigger('click');

            $('.workout-content').css('height', 'auto');
        });

        $('.btn-reset-filter').on('click', function (e) {
            e.preventDefault();
            $('.meal-wrap').find('.wrap-card-row').removeClass('none');
            $('.meal-wrap').find('.wrap-row').removeClass('none');
            $('.week-list-filter-mb').removeClass('active');

            $('.meal-plan-choose-day a').removeClass('active');
            $('.meal-plan-day a').removeClass('active');
            
            $('#get-scroll-id').attr('val', '');
        })

    });
})(jQuery);