(function ($) {
    function sliderSearch() {
        $(".product-search").on('initialized.owl.carousel changed.owl.carousel', function (event) {
            var items = event.page.count,     // Number of items
                item = event.page.index,     // Position of the current item
                current_pagi = $(this).closest('.featured-products').find('.current-page'),
                total_pagi = $(this).closest('.featured-products').find('.total-page');
            current_pagi.html(item + 1 + " ");
            total_pagi.html("/" + items);
        }).owlCarousel({
            items: 4,
            margin: 20,
            dots: false,
            slideBy: 'page',
            responsive: {
                0: {
                    items: 1
                },
                641: {
                    items: 2
                },
                992: {
                    items: 3
                },
                1440: {
                    items: 4
                }
            }
        });

    }

    function loadMoreBtn() {
        var page = 0,
            isPageLoading = false;
        $(".loadmore-btn").on("click", function (e) {
            e.preventDefault();
            var $this = $(this);
            var urljson = $(this).data("urljson");
            var totalpage = $(this).data("totalpage");
            var excludeid = $(this).data("excludeid");
            page++;
            console.log("PAGE", page);
            if (page > totalpage || isPageLoading) {
                return;
            }
            if (page >= totalpage) {
                $this.fadeOut();
                $this.parent().hide();
            }
            isPageLoading = true;
            $this.text("LOADING...");
            $.get(urljson + "?page=" + page + (excludeid ? "&excludeid=" + excludeid : "")).done(function (data) {
                var $newItems = $(data);
                $(".row-product-list").append($newItems);
                $('html,body').animate({
                    scrollTop: $newItems.offset().top - $('.header-page').outerHeight() - 20
                }, 800);
                isPageLoading = false;
                $this.text($this.data("text"));
            }).fail(function () {
                isPageLoading = false;
                $this.text("FAIL TO GET DATA!!!");
            });

        });
    }

    $(function () {
        sliderSearch();
        loadMoreBtn();
    });

})(jQuery);
