(function ($) {
    $(function () {

        var flagAjax = false;
        $('.workout-content').on('click', '.owl-item.active .card-workout .btn-view-workout', function (event) {
            if($('#modal-workout-content').length < 1) {
                return false;
            }

            var $this = $(this);
            var $data_link = $this.data('url');

            //Flag check if ajax is successfully loaded - not allow second click
            if (flagAjax) return;
            flagAjax = true;

            $().ven_loading_show();
            //Calling Ajax on custom link to get according data

            $.get($data_link, function (data) {
                $().ven_loading_hide();
                $('#modal-workout-content .modal-body').html(data);
                $('#modal-workout-content').modal('show');                
            });
        });

        $('#modal-workout-content').on('hidden.bs.modal', function (e) {
            flagAjax = false;
        });

        if ($(window).width() > 576) {
            $('.owl-card .owl-item .cart-workout-desc-wrap').matchHeight();
        }
    });
})(jQuery);