(function ($) {
    $(function () {
        $(window).on('load', function () {
            $(".modal .wrap-form .pass").append("<i class='btn-eye btn-show-pass'></i>");
            $(".btn-show-pass").on("click", function (e) {
                e.preventDefault();
                $(this).toggleClass("showed");
                $(this).prev("input").toggleClass("showed").attr('type', $(this).prev("input").hasClass('showed') ? "text" : 'password');
            });
        });
        $(".show-register").on('click', function (e) {
            e.preventDefault();
            $("#modal-login").modal('hide');
            setTimeout(function () {
                $("#modal-register").modal("show");
            }, 350);
        });
        $(".show-login").on('click', function (e) {
            e.preventDefault();
            $("#modal-register").modal('hide');
            $("#modal-login").modal("show");
        });
        // $(".show-success").on('click', function (e) {
        //     e.preventDefault();
        //     $("#modal-register").modal('hide');
        //     $("#modal-success").modal("show");
        // });
        $(".modal .wrap-form .group-box select").select2({
            minimumResultsForSearch: -1,
        });
        
        // $('.menu-item').hover(function() {
        //     $('.header-page').css('background','#fff');
        // });
        // $('.menu-item').hover(
        //     function(){
        //         $(this).parents('.header-page').removeClass('change-color-white');
        //         $(this).parents('.header-page').addClass('change-color-gray');
        //    },
        //     function(){
        //         $(this).parents('.header-page').removeClass('change-color-gray');
        //         $(this).parents('.header-page').addClass('change-color-white');
        //     }
        
        // );
    });
})(jQuery);