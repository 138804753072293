(function ($) {
    function ui_account_slider() {
        if ($(".the-myaccount-wrapper").length < 1) return;
        var owl_slider = $(".the-myaccount-wrapper .wrap-slider");
        owl_slider.owlCarousel({
            items: 1,
            margin: 16,
            navText: ['', ''],
            nav: true,
            autoHeight: true
        });
        $(".the-myaccount-wrapper .wrap-slider .owl-dots").each(function () {
            $(this).find("button").each(function (indexChild) {
                var addIndex = indexChild + 1;
                var number = (addIndex < 10 ? '0' : '') + addIndex;
                $(this).find("span").text(number);
            });

        })
    }
    function ui_layout_acc() {
        if ($(".the-myaccount-wrapper").length < 1) return;
        var $grid = $(".section-run-moments .wrap-slider .grid").isotope({
            itemSelector: '.grid-item',
            gutter: 16,
            percentPosition: true,
            masonry: {
                horizontalOrder: false,
                columnWidth: '.grid-sizer'
            }
        });
        // layout Isotope after each image loads
        $grid.imagesLoaded().progress(function () {
            $grid.isotope('layout');
        });

    }
    function ui_sticky_acc() {
        if ($(".the-myaccount-wrapper").length < 1) return;
        var funcScroll = function () {
            var sTop = $(window).scrollTop(),
                $wrap_info = $(".wrap-info-account"),
                $hide_fix = $(".the-myaccount-content").outerHeight(true),
                header_fix = $("header.header-page.fixed").outerHeight(true);
            if (sTop >= $wrap_info.offset().top + header_fix + (($("body").hasClass("admin-bar") && $(window).width() >= 768) ? 32 : 0)) {
                $wrap_info.addClass("fixed");
            }
            if (sTop >= $hide_fix || sTop <= $(".the-myaccount-content").offset().top) {
                $wrap_info.removeClass("fixed");
            }
            console.log(sTop, $hide_fix);
        }
        funcScroll();
        $(window).on("scroll", funcScroll);
    }
    function ui_acc_sticky() {
        var $sticky = $(".the-myaccount-sidebar");
        if ($sticky.length < 1) { return; }
        if ($(window).width() >= 992) { $sticky.stick_in_parent({ offset_top: $("header.header-page").height() - 5 + (($("body").hasClass("admin-bar") && $(window).width() >= 768) ? 32 : 0) }); }
        $(window).on("resize", function () {
            if ($(window).width() < 992) { $sticky.trigger("sticky_kit:detach"); }
        });
    }
    function remove_focus() {
        $('.woocommerce-Input').focus(function (e) {
            $(this).css("border", "1px solid #a7aaaa");
        })
    }
    function edit_account_field() {
        $('.action-edit').on('click', function (e) {
            e.preventDefault();
            $(this).toggleClass('hide');
            var $next = $(this).next().toggleClass('active');
            var $parents = $(this).parents('.address-edit');
            var $parent_form = $parents.find('.address-edit-field');
            $parent_form.find('label').toggleClass('hide');
            $parent_form.find('input').toggleClass('active');
        });

        $('#form-address-edit').submit(function(event) {
            event.preventDefault();     
            var error = false;
            var wrap_loading = $(this);
            var data = wrap_loading.serialize();
            var $ajax_url = $('#ajax_url').val() + 'ven-account-settings';

            $("span.msg-error").remove();
            $('.row-error').removeClass('row-error');

            if($('.validate-required').length > 0) {
                $('.validate-required').each(function() {

                    if($(this).find('input').val() == "" || $(this).find('select').val() == "") {
                        message = '<span class="msg-error">' + $(this).find('label').text() + 'is a require field. </span>';
                        message = message.replace("*", "");

                        $(this).append(message);
                        $(this).addClass('row-error');

                        error = true;
                    }
                });
            }

            if(error == true) {
                return false;
            }

            $.ajax({
                url: $ajax_url,
                type: 'POST',
                dataType: 'json',
                cache: false,
                data: data,
                beforeSend: function (xhr) {
                    $().ven_loading_show();
                }
            }) .done(function (res) {
                if( !res.success ) {
                    //Error
                    var message = ( typeof response.data.message != 'undefined' ) ? response.data.message : '';
                    $('.msg-error-form').html(message);
                    $().ven_loading_hide();
                } else {
                    $('.field-shipping-warp .button-wap .btn-cancel').trigger("click");

                    /*Update data*/
                    if($('.edit-setting-address .field-billing-warp .no-edit').length < 1) {
                        return false;
                    }

                    $('.edit-setting-address .field-billing-warp .no-edit').each(function() {
                        var value = '';
                        if($(this).find('input').length > 0) {
                            value = $(this).find('input').val();
                        }

                        if($(this).find('select').length > 0) {
                            value = $(this).find('select option:selected').text();
                        }

                        $(this).find('.field-billing-view').html(value);
                    });

                    $().ven_loading_hide();
                }
            });
            
        });
    }

    function order_reviewer() {
        $('.order-reviews').on('click', function (e) {
            e.preventDefault();
            var order_id = $(this).data('id');
            var $wrap_loading = $('.my-order-history');
            $.ajax({
                url: ven_ajax_vars.ven_ajax_url,
                type: 'post',
                data: {
                    'action': 'get_review_order',
                    'order_id': order_id,
                    'security': ven_ajax_vars.x_wp_nonce
                },
                beforeSend: function (xhr) {
                    $wrap_loading.append('<div class="wrap-loading"><div class="wrtw-loading"></div></div>');
                }
            })
                .done(function (res) {
                    $('#order-details .modal-body').html(res.html)

                    $('#order-details').modal('show');

                    $wrap_loading.find('.wrap-loading').remove();
                    $('.modal-order-review').addClass('active');
                    $('.modal-order-review').append(res.html);
                })
        });
    }
    function get_review_order() {
        $('.modal-close').on('click', function (e) {
            e.preventDefault();
            var modal_parents = $(this).parents('.modal-order-review');
            modal_parents.removeClass('active');
            var modal_details = modal_parents.find('.order-details');
            modal_details.remove();
        });
    }

    function display_searchbox() {
        $('.wrap-friend .btn-search').on('click', function (e) {
            e.preventDefault();
            $('#box-search-friend').fadeIn();
            $(this).hide();
        });
    }

    /**
    * Dis play tooltip in Training Plans and Nutrition Plans
    */
    function ven_display_tooltip() {
        $('[data-toggle="tooltip"]').tooltip({
            'html' : true,
            'placement' : function() {
                if($(window).width() > 767) {
                    return 'right';
                } else {
                    return 'top';
                }

            },
            'title' : function(){
                var content = $(this).parent().parent().find(".wrap-product-description").html();
                return content;
            }
        });
    }

    /**
    * Display Fancy Box My Run The World Moments
    */
    function ven_load_fancy_box() {
        if($('[data-fancybox="gallery"]').length < 1) {
            return false;
        }

        $('[data-fancybox="gallery"]').fancybox({
            buttons: [
                "thumbs",
                "close"
            ]
        });
    }

    /**
    * Show error edit account
    */
    function ven_show_error_edit_account() {
        if($('.field-billing-warp .row-error .description').length < 1) {
            return false;
        }

        $('.field-billing-warp .row-error .description').addClass('msg-error');
        $('.field-billing-warp .row-error .description').removeClass('description');
    }

    /**
    * Check billing state field
    */
    function ven_check_billing_state_field() {
        if($('.field-billing-warp #billing_state_field').length < 1) {
            return false;
        }

        var elem = $('.field-billing-warp #billing_state_field');

        if(elem.is(":visible")){
            $('#billing_state').attr('name', 'billing_state');
        } else{
            $('#billing_state').attr('name', 'billing_states');
        }        
    }

    /**
    * Change country field
    */
    function ven_change_country_field() {
        if($('.field-billing-warp #billing_country_field').length < 1) {
            return false;
        }

        $('.field-billing-warp #billing_country_field').on('select2:select', function (e) {
          ven_check_billing_state_field();
        });
    }

    /**
    * Enable edit shipping in my account page
    */
    function ven_enable_edit_shipping() {
        if($('.field-shipping-warp .icon-edit-billing-field-wrap a').length < 1) {
            return false;
        }

        var address_2 = $('#shipping_address_2_field');

        if(address_2.find('input').val() == "") {
            address_2.hide();
        }

        $('.field-shipping-warp .icon-edit-billing-field-wrap a').on('click', function(e) {
            e.preventDefault();
            $('.field-shipping-warp').removeClass('field-shipping-edit-warp');
            $('.field-shipping-warp .no-edit').removeClass('no-edit');
            $('.field-shipping-warp input').removeAttr('readonly');
            $('.icon-edit-billing-field-wrap').addClass('d-none');
            $('.field-shipping-warp .button-wap').removeClass('d-none');

            $(".field-shipping-warp input").each( function(){
                $(this).attr('placeholder',$(this).data('placeholder'));                   
            });

            address_2.show();
        });

        $('.field-shipping-warp .button-wap .btn-cancel').on('click', function(e) {
            e.preventDefault();
            $('.field-shipping-warp').addClass('field-shipping-edit-warp');
            $('.field-shipping-warp .form-row').addClass('no-edit');
            $('.field-shipping-warp input').attr('readonly', 'readonly');

            $('.icon-edit-billing-field-wrap').removeClass('d-none');
            $('.field-shipping-warp .button-wap').addClass('d-none');

            $(".field-shipping-warp input").each( function(){
                $(this).attr('placeholder','');                    
            });

            if(address_2.find('input').val() == "") {
                address_2.hide();
            }
        });
    }
   
    function ven_view_shipping_address() {
        if($('.edit-setting-address .field-billing-warp .no-edit').length < 1) {
            return false;
        }

        /*if($('#billing_address_2_field label').length < 1) {
            text = $('#billing_address_2_field').find('input').attr('');
            $('#billing_address_2_field').prepend('<label for="billing_address_2" class="screen-reader-text">Apartment, suite, unit etc.&nbsp;<span class="optional">(optional)</span></label>');
        }*/
        

        $('.edit-setting-address .field-billing-warp .no-edit').each(function() {
            var value = '';
            if($(this).find('input').length > 0) {
                value = $(this).find('input').val();
            }

            if($(this).find('select').length > 0) {
                value = $(this).find('select option:selected').text();
            }

            $(this).append('<span class="field-billing-view">'+ value +'</span>')
        });
    }

    $(function () {
        order_reviewer();
        get_review_order();
        edit_account_field();
        remove_focus();
        ui_account_slider();
        ui_layout_acc();
        
        //ui_sticky_acc();
        ui_acc_sticky();
        display_searchbox();

        ven_display_tooltip();
        ven_load_fancy_box();

        ven_show_error_edit_account();
        ven_check_billing_state_field();
        ven_change_country_field();
        ven_enable_edit_shipping();

        ven_view_shipping_address();

    });

})(jQuery);